import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AdminService } from 'services/AdminService';
import { HttpClient } from 'services/http-client';
import { queryKeys } from './queryKey';
import { UserInfoDto } from 'services/data-contracts';
import parsePageInfoFromHeaders from '../utils/parsePageInfoFromHeaders';
import PageInfo from '../types/PageInfo';

const adminService = new AdminService(new HttpClient());

// Define a new type for the custom query result to include the UseQueryResult for UserDto[].
type CustomQueryResult<T> = UseQueryResult<T, AxiosError>;

interface UsersResponse {
  users: UserInfoDto[];
  pageInfo: PageInfo;
}

/**
 * 사용자 목록을 가져오는 커스텀 훅
 *
 * @param options UseQueryOptions<UserDto[], AxiosError> 타입의 옵션 객체 (선택 사항)
 * @returns CustomQueryResult<UserDto[]> 타입의 쿼리 결과 객체
 */

export function useUsersQuery(
  filterOptions: {
    userName?: string;
    page?: number;
    size?: number;
    sort?: string[];
  } = {},

  options?: UseQueryOptions<UsersResponse, AxiosError>,
): CustomQueryResult<UsersResponse> {
  const fetchUsers = async () => {
    const query = { ...filterOptions };

    const response = await adminService.getUsers(query);
    const pageInfo = parsePageInfoFromHeaders(response.headers);

    return { users: response.data, pageInfo };
  };

  return useQuery<UsersResponse, AxiosError>(
    [queryKeys.users, filterOptions],
    fetchUsers,
    options,
  );
}

/**
 * 특정 사용자 정보를 가져오는 커스텀 훅
 *
 * @param userId 가져올 사용자의 ID
 * @param options UseQueryOptions<UserDto, AxiosError> 타입의 옵션 객체 (선택 사항)
 * @returns CustomQueryResult<UserDto> 타입의 쿼리 결과 객체
 */
export function useUserQuery(
  userId: number,
  options?: UseQueryOptions<UserInfoDto, AxiosError>,
): CustomQueryResult<UserInfoDto> {
  const fetchUser = async () => {
    const response = await adminService.getUser1(userId);
    return response.data;
  };

  return useQuery<UserInfoDto, AxiosError>(
    queryKeys.userById(userId),
    fetchUser,
    options,
  );
}
