// src/utils/parsePageInfoFromHeaders.ts

import PageInfo from '../types/PageInfo';

function parsePageInfoFromHeaders(headers: any): PageInfo {
  const xPaginationHeader = headers['x-pagination'];
  if (!xPaginationHeader) {
    throw new Error('x-pagination header not found in response');
  }

  const pageInfo: PageInfo = JSON.parse(xPaginationHeader);
  return pageInfo;
}

export default parsePageInfoFromHeaders;
