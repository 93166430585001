// import React, { useState } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  // Paper,
  // Tab,
  // Tabs,
  // Typography,
} from '@mui/material';
// import Grid from '@mui/material/Grid';

import { useUserQuery } from 'queries/useUser';
import styled from 'styled-components';

// 팝업 컴포넌트
interface UserDetailsPopupProps {
  userId: number;
  open: boolean;
  onClose: () => void;
}

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 80,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'channelName',
    headerName: '채널명',
    width: 160,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  // {
  //   field: 'nickname',
  //   headerName: '닉네임',
  //   width: 150,
  //   align: 'center',
  //   headerAlign: 'center',
  //   valueGetter: (params) => {
  //     return params.row.profile.nickname;
  //   },
  // },
  {
    field: 'isBusiness',
    headerName: 'Biz',
    width: 110,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.isBusiness ? 'O' : '-';
    },
  },
  {
    field: 'coin',
    headerName: 'Coin',
    width: 120,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'followerCount',
    headerName: '팔로워 수',
    width: 115,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'viewCount',
    headerName: '조회 수',
    width: 115,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },

  {
    field: 'createDate',
    headerName: '생성일',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    valueFormatter: (params) => {
      const createDate = new Date(params.value).toLocaleString('ko-KR', {
        timeZone: 'Asia/Seoul',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      });
      return createDate;
    },
  },
];

const UserDetailsPopup = ({ userId, open, onClose }: UserDetailsPopupProps) => {
  // const [selectedTab, setSelectedTab] = useState(0);
  const { data: user } = useUserQuery(Number(userId));

  const formatSignupDate = (date: string) => {
    if (!date) {
      return '-';
    }

    const formattedDate = new Date(date).toLocaleString('ko-KR', {
      timeZone: 'Asia/Seoul',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });
    return formattedDate;
  };

  // const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setSelectedTab(newValue);
  // };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Title>사용자 정보</Title>
      <DialogContent>
        {user && (
          <>
            {/* <StyledPaper elevation={3}> */}
            <StyledContainer>
              <SubTitle>기본정보</SubTitle>
              <StyledTable>
                <tbody>
                  <StyledTableRow>
                    <StyledTableCell variant="header">User ID</StyledTableCell>
                    <StyledTableCell>{user.id}</StyledTableCell>
                    <StyledTableCell variant="header">Email</StyledTableCell>
                    <StyledTableCell>{user.username}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell variant="header">가입유형</StyledTableCell>
                    <StyledTableCell>
                      {user.loginType === 'Social'
                        ? user.provider
                        : user.loginType}
                    </StyledTableCell>
                    <StyledTableCell variant="header">가입일</StyledTableCell>
                    <StyledTableCell>
                      {formatSignupDate(user.signupDate!)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell variant="header">휴면여부</StyledTableCell>
                    <StyledTableCell>
                      {user.isDormant ? 'O' : '-'}
                    </StyledTableCell>
                    <StyledTableCell variant="header">탈퇴여부</StyledTableCell>
                    <StyledTableCell>
                      {user.isWithdrawn ? 'O' : '-'}
                    </StyledTableCell>
                  </StyledTableRow>
                </tbody>
              </StyledTable>
              {/* </StyledPaper> */}

              <SubTitle>채널목록</SubTitle>
              {user.channels && (
                <StyledDataGrid
                  rows={user.channels}
                  disableColumnMenu
                  columns={columns}
                  rowCount={user.channelCount || 0}
                  paginationMode="client"
                  autoHeight
                  hideFooter={true}
                  density="compact"
                />
              )}
            </StyledContainer>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UserDetailsPopup;

const Title = styled(DialogTitle)`
  background-color: #00632b; /* 원하는 배경색으로 설정하세요 */
  color: #fff; /* 글자색 설정 */
  font-family: inherit;
`;

const StyledContainer = styled.div`
  width: 100%;
  min-height: 300px; /* 팝업 레이어의 높이를 고정하는 부분 */
  overflow-x: auto; /* 가로 스크롤 추가 */
`;

const SubTitle = styled.div`
  background-color: #fff; /* 배경색 설정 */
  padding: 20px 0 5px 5px; /* 내부 타이틀의 패딩 설정 */

  font-weight: bold; /* 글꼴 두껍게 설정 */
  z-index: 1; /* 내부 타이틀이 다른 내용 위에 나타나도록 z-index 설정 */
`;
const StyledDataGrid = styled(DataGrid)`
  font-family: var(--font-nanumfont) !important;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  width: 100%; /* 너비 100%로 설정 */

  .MuiDataGrid-columnHeader {
    background-color: #f0f0f0;
    font-weight: bold;
  }

  .MuiDataGrid-row {
    &:nth-of-type(odd) {
      background-color: #f8f8f8;
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const StyledTableRow = styled.tr`
  border-bottom: 1px solid #ddd;
  &:last-child {
    border-bottom: none; /* 마지막 행에는 border-bottom을 적용하지 않음 */
  }
`;

const StyledTableCell = styled.td<{ variant?: 'header' }>`
  padding: 8px;
  text-align: center;
  ${({ variant }) =>
    variant === 'header' &&
    `
    background-color: #f3f3f3;
    font-weight: bold;
  `}
`;
