import React, { useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@mui/material';
import { useChannelQuery, useConvertToBizMutation } from 'queries/useChannel';
import styled from 'styled-components';
import { useAddCoinMutation, useCoinHistoryQuery } from 'queries/useCoin';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';

const PAGE_SIZE = 5;

// 팝업 컴포넌트
interface ChannelDetailsPopupProps {
  channelId: number;
  open: boolean;
  onClose: () => void;
}

const columns: GridColDef[] = [
  {
    field: 'transactionDate',
    headerName: '일자',
    width: 200,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    valueFormatter: (params) => {
      const transactionDate = new Date(params.value).toLocaleString('ko-KR', {
        timeZone: 'Asia/Seoul',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      });
      return transactionDate;
    },
  },

  {
    field: 'status',
    headerName: '구분',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.status === 'EARN' ? '적립' : '사용';
    },
  },
  {
    field: 'coin',
    headerName: 'Coin',
    width: 200,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'description',
    headerName: '설명',
    width: 300,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
];

const ChannelDetailsPopup = ({
  channelId,
  open,
  onClose,
}: ChannelDetailsPopupProps) => {
  const queryClient = useQueryClient();

  const [selectedTab, setSelectedTab] = useState(0);
  const [coinToGive, setCoinToGive] = useState(''); // 입력된 코인 값을 상태로 관리

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({
      page: 0,
      pageSize: PAGE_SIZE,
    });

  const [sortModel, setSortModel] = React.useState<string[]>(['id,desc']);

  const { data: channel } = useChannelQuery(Number(channelId));

  const { data: coinData } = useCoinHistoryQuery(Number(channelId), {
    page: paginationModel.page,
    size: paginationModel.pageSize,
    sort: sortModel,
  });

  const { mutate: bizMutate } = useConvertToBizMutation();

  const { mutate: coinMutate } = useAddCoinMutation();

  // 데이터 및 페이지 정보 추출
  const coins = coinData?.coins ?? [];
  const pageInfo = coinData?.pageInfo;

  const formatDate = (date: string) => {
    if (!date) {
      return '-';
    }

    const formattedDate = new Date(date).toLocaleString('ko-KR', {
      timeZone: 'Asia/Seoul',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });
    return formattedDate;
  };

  // 페이지 변경 핸들러
  const handlePaginationModelChange = (
    paginationModel: GridPaginationModel,
  ) => {
    setPaginationModel(paginationModel);
  };

  const handleBizTransitionClick = async () => {
    const confirmBizTransition = window.confirm(
      '정말로 비즈채널로 전환하시겠습니까?',
    );

    if (confirmBizTransition) {
      bizMutate(
        { channelName: channel?.channelName },
        {
          onSuccess: async () => {
            toast.success('비즈채널로 전환되었습니다!');
            await queryClient.refetchQueries();
          },
        },
      );
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Input 값이 변경될 때 호출되는 핸들러
    setCoinToGive(e.target.value);
  };

  const handleCoinGiveClick = async () => {
    if (!coinToGive) {
      toast.error('코인 값을 입력하세요.');
      return;
    }

    const confirmCoinGive = window.confirm('정말로 코인을 지급하시겠습니까?');

    if (confirmCoinGive) {
      coinMutate(
        {
          coin: Number(coinToGive),
          targetChannel: { id: channel?.id },
        },
        {
          onSuccess: async () => {
            toast.success(`${coinToGive} 코인을 지급했습니다.`);
            setCoinToGive('');

            await queryClient.refetchQueries();
          },
        },
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Title>{channel?.channelName} 채널 정보</Title>
      <DialogContent>
        {channel && (
          <>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="기본정보" />
              <Tab label="코인사용내역" />

              {channel.isActive && !channel.isDelete && (
                <Tab label="채널설정" />
              )}
            </Tabs>

            <div role="tabpanel" hidden={selectedTab !== 0}>
              <StyledContainer>
                <StyledTable>
                  <tbody>
                    <StyledTableRow>
                      <StyledTableCell variant="header">ID</StyledTableCell>
                      <StyledTableCell>{channel.id}</StyledTableCell>
                      <StyledTableCell variant="header">채널명</StyledTableCell>
                      <StyledTableCell>{channel.channelName}</StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell variant="header">닉네임</StyledTableCell>
                      <StyledTableCell>
                        {channel.profile?.nickname}
                      </StyledTableCell>
                      <StyledTableCell variant="header">소유자</StyledTableCell>
                      <StyledTableCell>
                        {channel.creator?.username}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell variant="header">Biz</StyledTableCell>
                      <StyledTableCell>
                        {channel.isBusiness ? '비즈채널' : '일반채널'}
                      </StyledTableCell>
                      <StyledTableCell variant="header">
                        보유코인
                      </StyledTableCell>
                      <StyledTableCell>
                        {channel.coin?.toLocaleString()}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell variant="header">팔로워</StyledTableCell>
                      <StyledTableCell>
                        {channel.followerCount?.toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell variant="header">팔로잉</StyledTableCell>
                      <StyledTableCell>
                        {channel.followingCount?.toLocaleString()}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell variant="header">조회수</StyledTableCell>
                      <StyledTableCell>
                        {channel.viewCount?.toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell variant="header">생성일</StyledTableCell>
                      <StyledTableCell>
                        {formatDate(channel.createDate!)}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell variant="header">
                        삭제여부
                      </StyledTableCell>
                      <StyledTableCell>
                        {channel.isDelete ? 'O' : '-'}
                      </StyledTableCell>
                      <StyledTableCell variant="header">삭제일</StyledTableCell>
                      <StyledTableCell>
                        {formatDate(channel.deleteDate!)}
                      </StyledTableCell>
                    </StyledTableRow>
                  </tbody>
                </StyledTable>
              </StyledContainer>
            </div>

            <div role="tabpanel" hidden={selectedTab !== 1}>
              <StyledContainer>
                {coins.length > 0 ? (
                  <>
                    {/* 코인사용내역 표시 */}
                    <StyledDataGrid
                      rows={coins}
                      disableColumnMenu
                      columns={columns}
                      rowCount={pageInfo?.totalCount || 0}
                      pageSizeOptions={[PAGE_SIZE]}
                      paginationModel={paginationModel}
                      paginationMode="server"
                      onPaginationModelChange={handlePaginationModelChange}
                      density="compact"
                    />
                  </>
                ) : (
                  <p style={{ textAlign: 'center' }}>조회된 정보가 없습니다.</p>
                )}
              </StyledContainer>
            </div>
            <div role="tabpanel" hidden={selectedTab !== 2}>
              <StyledContainer>
                <StyledTable>
                  <tbody>
                    <StyledTableRow>
                      <StyledTableCell variant="header">Biz</StyledTableCell>
                      <StyledTableCell style={{ textAlign: 'left' }}>
                        {channel.isBusiness ? (
                          '비즈채널'
                        ) : (
                          <button onClick={() => handleBizTransitionClick()}>
                            비즈전환
                          </button>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell variant="header">
                        보유코인 ({channel?.coin?.toLocaleString()})
                      </StyledTableCell>
                      <StyledTableCell>
                        <StyledCoinInput>
                          <Input
                            type="text"
                            placeholder="Coin입력"
                            value={coinToGive}
                            onChange={handleInputChange}
                          />
                          <button onClick={handleCoinGiveClick}>
                            코인지급
                          </button>
                        </StyledCoinInput>
                        {/* <InputWrapper>
                          <Input
                            type="text"
                            placeholder="Coin입력"
                            value={coinToGive}
                            onChange={handleInputChange}
                          />

                          <button onClick={handleCoinGiveClick}>
                            코인지급
                          </button>
                        </InputWrapper> */}
                      </StyledTableCell>
                    </StyledTableRow>
                  </tbody>
                </StyledTable>
              </StyledContainer>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ChannelDetailsPopup;

const Title = styled(DialogTitle)`
  background-color: #00632b; /* 원하는 배경색으로 설정하세요 */
  color: #fff; /* 글자색 설정 */
  font-family: inherit;
`;

const StyledContainer = styled.div`
  width: 100%;
  min-height: 280px; /* 팝업 레이어의 높이를 고정하는 부분 */
  padding-top: 10px;
  overflow-x: auto; /* 가로 스크롤 추가 */
`;

// const SubTitle = styled.div`
//   background-color: #fff; /* 배경색 설정 */
//   padding: 20px 0 5px 5px; /* 내부 타이틀의 패딩 설정 */

//   font-weight: bold; /* 글꼴 두껍게 설정 */
//   z-index: 1; /* 내부 타이틀이 다른 내용 위에 나타나도록 z-index 설정 */
// `;
const StyledDataGrid = styled(DataGrid)`
  font-family: var(--font-nanumfont) !important;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  width: 100%; /* 너비 100%로 설정 */

  .MuiDataGrid-columnHeader {
    background-color: #f0f0f0;
    font-weight: bold;
  }

  .MuiDataGrid-row {
    &:nth-of-type(odd) {
      background-color: #f8f8f8;
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const StyledTableRow = styled.tr`
  border-bottom: 1px solid #ddd;
  &:last-child {
    border-bottom: none; /* 마지막 행에는 border-bottom을 적용하지 않음 */
  }
`;

const StyledTableCell = styled.td<{ variant?: 'header' }>`
  width: 150px;
  padding: 8px;
  text-align: center;
  ${({ variant }) =>
    variant === 'header' &&
    `
    background-color: #f3f3f3;
    font-weight: bold;
  `}
`;

// const InputWrapper = styled.div`
//   flex: 0.5;
//   margin-right: 10px;
// `;

const Input = styled.input`
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: var(--font-nanumfont);
  ::placeholder {
    color: #999;
    font-size: 12px;
    font-family: var(--font-nanumfont);
  }
`;

const StyledCoinInput = styled.div`
  display: flex;
  align-items: center; /* 수직 중앙 정렬 */

  input {
    margin-right: 10px; /* input과 버튼 사이 간격 조절 */
  }
`;
