/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ChangePasswordDto,
  CheckDuplicateUsernameData,
  DeactivateUserData,
  ForgotPasswordData,
  ForgotPasswordDto,
  GetKakaoAccessTokenData,
  GetUserTokenData,
  LoginData,
  LoginDto,
  LoginWithAppleData,
  LoginWithGoogleData,
  LoginWithKakaoData,
  LogoutData,
  ReactivateUserData,
  RefreshTokenData,
  ResetPasswordData,
  SignUpDto,
  SignUpWithAppleData,
  SignUpWithEmailData,
  SignUpWithGoogleData,
  SignUpWithKakaoData,
  SocialLoginDto,
  SocialSignUpDto,
  TokenDto,
  VerifyEmailData,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class AuthController<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 로그인 토큰 발급(for test)
   *
   * @tags auth-controller
   * @name GetUserToken
   * @summary 로그인 토큰 발급(for test)
   * @request POST:/v2/auth/{userId}/token
   * @secure
   * @response `200` `GetUserTokenData` OK
   */
  getUserToken = (
    userId: number,
    query: {
      deviceToken: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetUserTokenData, any>({
      path: `/v2/auth/${userId}/token`,
      method: 'POST',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description 휴면해제(for test)
   *
   * @tags auth-controller
   * @name ReactivateUser
   * @summary 휴면해제(for test)
   * @request POST:/v2/auth/{userId}/release-dormant
   * @deprecated
   * @secure
   * @response `200` `ReactivateUserData` OK
   */
  reactivateUser = (userId: number, params: RequestParams = {}) =>
    this.http.request<ReactivateUserData, any>({
      path: `/v2/auth/${userId}/release-dormant`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 휴면처리(for test)
   *
   * @tags auth-controller
   * @name DeactivateUser
   * @summary 휴면처리(for test)
   * @request POST:/v2/auth/{userId}/dormant
   * @deprecated
   * @secure
   * @response `200` `DeactivateUserData` OK
   */
  deactivateUser = (userId: number, params: RequestParams = {}) =>
    this.http.request<DeactivateUserData, any>({
      path: `/v2/auth/${userId}/dormant`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 회원가입 (가입인증 링크 메일발송)
   *
   * @tags auth-controller
   * @name SignUpWithEmail
   * @summary 회원가입 (가입인증 링크 메일발송)
   * @request POST:/v2/auth/sign-up
   * @secure
   * @response `200` `SignUpWithEmailData` OK
   */
  signUpWithEmail = (data: SignUpDto, params: RequestParams = {}) =>
    this.http.request<SignUpWithEmailData, any>({
      path: `/v2/auth/sign-up`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 카카오 회원가입
   *
   * @tags auth-controller
   * @name SignUpWithKakao
   * @summary 카카오 회원가입
   * @request POST:/v2/auth/sign-up/kakao
   * @secure
   * @response `200` `SignUpWithKakaoData` OK
   */
  signUpWithKakao = (data: SocialSignUpDto, params: RequestParams = {}) =>
    this.http.request<SignUpWithKakaoData, any>({
      path: `/v2/auth/sign-up/kakao`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 구글 회원가입
   *
   * @tags auth-controller
   * @name SignUpWithGoogle
   * @summary 구글 회원가입
   * @request POST:/v2/auth/sign-up/google
   * @secure
   * @response `200` `SignUpWithGoogleData` OK
   */
  signUpWithGoogle = (data: SocialSignUpDto, params: RequestParams = {}) =>
    this.http.request<SignUpWithGoogleData, any>({
      path: `/v2/auth/sign-up/google`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 애플 회원가입
   *
   * @tags auth-controller
   * @name SignUpWithApple
   * @summary 애플 회원가입
   * @request POST:/v2/auth/sign-up/apple
   * @secure
   * @response `200` `SignUpWithAppleData` OK
   */
  signUpWithApple = (data: SocialSignUpDto, params: RequestParams = {}) =>
    this.http.request<SignUpWithAppleData, any>({
      path: `/v2/auth/sign-up/apple`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 비밀번호 리셋
   *
   * @tags auth-controller
   * @name ResetPassword
   * @summary 비밀번호 리셋
   * @request POST:/v2/auth/reset-password
   * @secure
   * @response `200` `ResetPasswordData` OK
   */
  resetPassword = (
    query: {
      token: string;
    },
    data: ChangePasswordDto,
    params: RequestParams = {},
  ) =>
    this.http.request<ResetPasswordData, any>({
      path: `/v2/auth/reset-password`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 토큰 재발급
   *
   * @tags auth-controller
   * @name RefreshToken
   * @summary 토큰 재발급
   * @request POST:/v2/auth/refresh-token
   * @secure
   * @response `200` `RefreshTokenData` OK
   */
  refreshToken = (data: TokenDto, params: RequestParams = {}) =>
    this.http.request<RefreshTokenData, any>({
      path: `/v2/auth/refresh-token`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 로그아웃
   *
   * @tags auth-controller
   * @name Logout
   * @summary 로그아웃
   * @request POST:/v2/auth/logout
   * @secure
   * @response `200` `LogoutData` OK
   */
  logout = (data: TokenDto, params: RequestParams = {}) =>
    this.http.request<LogoutData, any>({
      path: `/v2/auth/logout`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 로그인
   *
   * @tags auth-controller
   * @name Login
   * @summary 로그인
   * @request POST:/v2/auth/login
   * @secure
   * @response `200` `LoginData` OK
   */
  login = (data: LoginDto, params: RequestParams = {}) =>
    this.http.request<LoginData, any>({
      path: `/v2/auth/login`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 카카오 간편로그인
   *
   * @tags auth-controller
   * @name LoginWithKakao
   * @summary 카카오 간편로그인
   * @request POST:/v2/auth/login/kakao
   * @secure
   * @response `200` `LoginWithKakaoData` OK
   */
  loginWithKakao = (data: SocialLoginDto, params: RequestParams = {}) =>
    this.http.request<LoginWithKakaoData, any>({
      path: `/v2/auth/login/kakao`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 구글 간편로그인
   *
   * @tags auth-controller
   * @name LoginWithGoogle
   * @summary 구글 간편로그인
   * @request POST:/v2/auth/login/google
   * @secure
   * @response `200` `LoginWithGoogleData` OK
   */
  loginWithGoogle = (data: SocialLoginDto, params: RequestParams = {}) =>
    this.http.request<LoginWithGoogleData, any>({
      path: `/v2/auth/login/google`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 애플 간편로그인
   *
   * @tags auth-controller
   * @name LoginWithApple
   * @summary 애플 간편로그인
   * @request POST:/v2/auth/login/apple
   * @secure
   * @response `200` `LoginWithAppleData` OK
   */
  loginWithApple = (data: SocialLoginDto, params: RequestParams = {}) =>
    this.http.request<LoginWithAppleData, any>({
      path: `/v2/auth/login/apple`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 비밀번호 찾기 (비밀번호리셋 링크 메일발송)
   *
   * @tags auth-controller
   * @name ForgotPassword
   * @summary 비밀번호 찾기 (비밀번호리셋 링크 메일발송)
   * @request POST:/v2/auth/forgot-password
   * @secure
   * @response `200` `ForgotPasswordData` OK
   */
  forgotPassword = (data: ForgotPasswordDto, params: RequestParams = {}) =>
    this.http.request<ForgotPasswordData, any>({
      path: `/v2/auth/forgot-password`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 회원가입 인증
   *
   * @tags auth-controller
   * @name VerifyEmail
   * @summary 회원가입 인증
   * @request GET:/v2/auth/verify
   * @secure
   * @response `200` `VerifyEmailData` OK
   */
  verifyEmail = (
    query: {
      token: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<VerifyEmailData, any>({
      path: `/v2/auth/verify`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description 카카오토큰가져오기
   *
   * @tags auth-controller
   * @name GetKakaoAccessToken
   * @summary 카카오토큰가져오기
   * @request GET:/v2/auth/kakao/token
   * @deprecated
   * @secure
   * @response `200` `GetKakaoAccessTokenData` OK
   */
  getKakaoAccessToken = (
    query: {
      code: string;
      redirect_uri: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetKakaoAccessTokenData, any>({
      path: `/v2/auth/kakao/token`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description 로그인 계정 중복 체크
   *
   * @tags auth-controller
   * @name CheckDuplicateUsername
   * @summary 로그인 계정 중복 체크
   * @request GET:/v2/auth/duplicate-check/user-name
   * @secure
   * @response `200` `CheckDuplicateUsernameData` OK
   */
  checkDuplicateUsername = (
    query: {
      userName: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CheckDuplicateUsernameData, any>({
      path: `/v2/auth/duplicate-check/user-name`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
}
