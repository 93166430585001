import * as React from 'react';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useMagazinesQuery } from 'queries/useMagazine';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import MagazineDetailsPopup from './MagazineDetailsPopup';
import MagazineFilter from 'components/MagazineFilter';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const PAGE_SIZE = 10;

const MagazinePage = () => {
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      sortable: true,
    },
    {
      field: 'title',
      headerName: '제목',
      minWidth: 300,
      flex: 0.8,
      align: 'center',
      headerAlign: 'center',
      sortable: true,
      renderCell: (params) => (
        <div
          onClick={() => handleMagazineClick(params.row.id)}
          style={{ cursor: 'pointer', fontWeight: 700 }}
        >
          <b>{params.row.title}</b>
        </div>
      ),
    },
    {
      field: 'topic',
      headerName: '토픽',
      minWidth: 200,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      sortable: true,
    },
    {
      field: 'author',
      headerName: '글쓴이',
      width: 120,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      // valueGetter: (params) => {
      //   return params.row.profile.nickname;
      // },
    },
    {
      field: 'publishDate',
      headerName: '개시일',
      width: 120,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      sortable: true,
      valueGetter: (params) => {
        const createDate = new Date(params.value).toLocaleString('ko-KR', {
          timeZone: 'Asia/Seoul',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        return createDate;
      },
    },
    {
      field: 'isBanner',
      headerName: '배너여부',
      width: 90,
      align: 'center',
      headerAlign: 'center',
      sortable: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.isBanner ? 'O' : '-';
      },
    },
    {
      field: 'isActive',
      headerName: '활성여부',
      width: 90,
      align: 'center',
      headerAlign: 'center',
      sortable: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.isActive ? 'O' : '-';
      },
    },
    {
      field: 'viewCount',
      headerName: '조회수',
      width: 90,
      align: 'right',
      headerAlign: 'right',
      sortable: true,
      valueFormatter: (params) => {
        return params.value.toLocaleString();
      },
    },
    // {
    //   field: 'creator',
    //   headerName: '생성자',
    //   width: 200,
    //   align: 'center',
    //   headerAlign: 'center',
    //   sortable: true,
    //   valueGetter: (params) => {
    //     return params.row.creator.userName;
    //   },
    // },
    {
      field: 'createDate',
      headerName: '생성일',
      width: 120,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      sortable: true,
      valueGetter: (params) => {
        const createDate = new Date(params.value).toLocaleString('ko-KR', {
          timeZone: 'Asia/Seoul',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        return createDate;
      },
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => (
        // <button onClick={() => handleMagazineClick(params.row.id)}>
        //   상세보기
        // </button>
        <Button
          onClick={() => {
            handleMagazineClick(params.row.id);
          }}
          color="info"
          variant="outlined"
        >
          상세보기
        </Button>
      ),
    },
  ];

  // const [, startTransition] = React.useTransition();
  const [filter, setFilter] = React.useState({
    searchText: '',
    bannerOnly: undefined,
    activeOnly: undefined,
  });

  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({
      page: 0,
      pageSize: PAGE_SIZE,
    });

  const [sortModel, setSortModel] = React.useState<string[]>(['id,desc']);
  const [magazines, setMagazines] = React.useState<any>();

  // 선택한 매거진 정보
  const [selectedMagazineId, setSelectedMagazineId] = React.useState<number>(0);

  // 팝업 레이어 열림/닫힘 상태
  const [isPopupOpen, setPopupOpen] = React.useState(false);

  const { data, isLoading, error, refetch } = useMagazinesQuery({
    searchText: filter.searchText,
    isBanner: filter.bannerOnly,
    isActive: filter.activeOnly,
    page: paginationModel.page,
    size: paginationModel.pageSize,
    sort: sortModel,
  });

  // 데이터 및 페이지 정보 추출
  // const magazines = data?.magazines ?? [];
  const pageInfo = data?.pageInfo;

  // 팝업 열기 함수
  const openPopup = (magazineId: number) => {
    if (magazineId > 0) {
      setSelectedMagazineId(magazineId);
      setPopupOpen(true);
    }
  };

  // 팝업 닫기 함수
  const closePopup = () => {
    // queryClient.invalidateQueries(queryKeys.magazines);
    setSelectedMagazineId(0);
    setPopupOpen(false);
  };

  // 필터 변경 핸들러
  const handleFilterChange = (newFilter: any) => {
    // 필터 및 페이지 초기화
    setPaginationModel({ page: 0, pageSize: PAGE_SIZE });
    setFilter(newFilter);
  };

  // 페이지 변경 핸들러
  const handlePaginationModelChange = (
    paginationModel: GridPaginationModel,
  ) => {
    setPaginationModel(paginationModel);
  };

  // 정렬 변경 핸들러
  const handleSortModelChange = (sortModel: GridSortModel) => {
    const transformedSort: string[] = sortModel.map((item) => {
      const sortOrder = item.sort === 'asc' ? 'asc' : 'desc';
      return `${item.field},${sortOrder}`;
    });
    setSortModel(transformedSort);
  };

  // 사용자 정보 클릭 핸들러
  const handleMagazineClick = (magazineId: number) => {
    navigate(`/magazines/${magazineId}/modify`);
    openPopup(magazineId);
  };

  // 에러 처리
  if (error) {
    toast.error(`Error: ${error.message}`);
  }

  const handleNewClick = () => {
    navigate('/magazines/create');
  };

  React.useEffect(() => {
    setMagazines(data?.magazines);
  }, [data?.magazines]);

  React.useEffect(() => {
    console.log('hihihi', magazines);
  }, [magazines]);

  React.useEffect(() => {
    refetch();
  }, []);

  return (
    <StyledContainer>
      <MagazineFilter
        filter={filter}
        onFilterChange={handleFilterChange}
        onNewClick={handleNewClick}
      />
      {/* <React.Suspense fallback={<div>Loading...</div>}> */}
      {magazines && (
        <StyledDataGrid
          rows={magazines}
          disableColumnMenu
          columns={columns}
          rowCount={pageInfo?.totalCount || 0}
          loading={isLoading}
          pageSizeOptions={[PAGE_SIZE]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          // onRowClick={(params) => handleMagazineClick(params.row)}
        />
      )}
      {/* 팝업 컴포넌트*/}
      {isPopupOpen && (
        <MagazineDetailsPopup
          magazineId={selectedMagazineId}
          open={isPopupOpen}
          onClose={closePopup}
        />
      )}
      {/* </React.Suspense> */}
    </StyledContainer>
  );
};

export default MagazinePage;

const StyledContainer = styled.div`
  margin-right: 20px;

  height: 650px;
  width: calc(100% - 40px);
  padding: 20px;
`;

const StyledDataGrid = styled(DataGrid)`
  font-family: var(--font-nanumfont) !important;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .MuiDataGrid-columnHeader {
    background-color: #f0f0f0; /* 헤더 배경색 */
    font-weight: bold;
  }

  .MuiDataGrid-row {
    &:nth-of-type(odd) {
      background-color: #f8f8f8; /* 홀수 줄 배경색 */
    }

    &:hover {
      background-color: #f0f0f0; /* 마우스 오버시 배경색 */
    }
  }

  .MuiDataGrid-cell {
    border: none; /* 셀 테두리 제거 */
  }
`;
