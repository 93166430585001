import { makeAutoObservable, toJS } from 'mobx';
import { FileUpload as FileUploadService } from 'services/FileUpload';

// import _ from "lodash";

import { FileDto } from 'services/data-contracts';
import { HttpClient } from 'services/http-client';

class FileStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: FileUploadService<unknown> = new FileUploadService(new HttpClient());

  /** 링크 이미지 업로드 */
  async uploadImage(file: any) {
    if (typeof file.publicUrl !== 'undefined') {
      delete file.publicUrl;
    }
    const response = (await this.api.uploadFile(
      { fileType: 'Magazine' },
      { file },
    )) as any;
    if (response.status < 300) {
      return response.data as File;
    } else {
      return response.error;
    }
  }

  // _base64ToArrayBuffer(base64: any) {
  //   var binary_string = window.atob(base64);
  //   var len = binary_string.length;
  //   var bytes = new Uint8Array(len);
  //   for (var i = 0; i < len; i++) {
  //     bytes[i] = binary_string.charCodeAt(i);
  //   }
  //   return bytes.buffer;
  // }
}

export default FileStore;
