import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import Header from './Header';

const LayoutWrapper = styled.div`
  padding-top: 50px;
`;

const Layout = () => {
  return (
    <LayoutWrapper>
      <Header />
      {<Outlet />}
    </LayoutWrapper>
  );
};

export default Layout;
