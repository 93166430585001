import React from 'react';
import styled from 'styled-components';

interface ChannelAuthenticationFilterProps {
  filter: {
    searchText: string;
    bizOnly?: boolean;
    deleteOlny?: boolean;
  };
  onFilterChange: (newFilter: any) => void;
}

const ChannelAuthenticationFilter: React.FC<
  ChannelAuthenticationFilterProps
> = ({ filter, onFilterChange }) => {
  const handleInputChange = (key: string, value: any) => {
    onFilterChange({ ...filter, [key]: value });
  };

  return (
    <FilterContainer>
      <InputWrapper>
        <SearchInput
          type="text"
          placeholder="검색어 입력"
          value={filter.searchText}
          onChange={(e) => handleInputChange('searchText', e.target.value)}
        />
      </InputWrapper>
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  height: 20px;
  /* width: 100%; */
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const InputWrapper = styled.div`
  flex: 0.5;
  margin-right: 10px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: var(--font-nanumfont);
  ::placeholder {
    color: #999;
    font-size: 12px;
    font-family: var(--font-nanumfont);
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.label`
  margin-left: 10px;
`;

const Checkbox = styled.input`
  margin-right: 5px;
`;

export default ChannelAuthenticationFilter;
