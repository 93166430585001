import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useMagazineQuery } from 'queries/useMagazine';
import styled from 'styled-components';

// 팝업 컴포넌트
interface MagazineDetailsPopupProps {
  magazineId: number;
  open: boolean;
  onClose: () => void;
}

const MagazineDetailsPopup = ({
  magazineId,
  open,
  onClose,
}: MagazineDetailsPopupProps) => {
  const { data: magazine } = useMagazineQuery(Number(magazineId));

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Title>매거진 정보</Title>
      <DialogContent>
        {magazine && (
          <>
            <div></div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MagazineDetailsPopup;

const Title = styled(DialogTitle)`
  background-color: #00632b;
  color: #fff;
  font-family: inherit;
`;

const StyledContainer = styled.div`
  width: 100%;
  min-height: 280px; /* 팝업 레이어의 높이를 고정하는 부분 */
  padding-top: 10px;
  overflow-x: auto; /* 가로 스크롤 추가 */
`;
