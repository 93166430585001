import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useAuthStore } from 'stores/AuthStore';
import { useNavigate } from 'react-router-dom';

/**
 *
 * @param SpecificComponent
 * @param option null: 아무나 접근 가능, true: 인증된 계정 접근가능, false: 인증된 계정 접근불가
 * @returns
 */
export default function withAuthentication(
  SpecificComponent: any,
  option: boolean,
) {
  const WithAuthenticationCheck: React.FC = observer(() => {
    const authStore = useAuthStore();
    const navigate = useNavigate();

    useEffect(() => {
      if (!authStore.isAuthenticated()) {
        if (option === true) {
          navigate('/login');
        }
      } else {
        if (option === false) {
          navigate('/');
        }
      }
    }, [authStore, navigate]);

    return <SpecificComponent />;
  });

  return WithAuthenticationCheck;
}
