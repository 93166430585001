import { AxiosError } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { AdminService } from 'services/AdminService';
import { HttpClient } from 'services/http-client';
import { queryKeys } from './queryKey';
import {
  ChannelDto,
  ConvertToBizData,
  SimpleChannelDto,
} from 'services/data-contracts';
import parsePageInfoFromHeaders from '../utils/parsePageInfoFromHeaders';
import PageInfo from '../types/PageInfo';

const adminService = new AdminService(new HttpClient());

// Define a new type for the custom query result to include the UseQueryResult for ChannelDto[].
type CustomQueryResult<T> = UseQueryResult<T, AxiosError>;

interface ChannelsResponse {
  channels: ChannelDto[];
  pageInfo: PageInfo;
}

/**
 * 채널 목록을 가져오는 커스텀 훅
 *
 * @param options UseQueryOptions<ChannelDto[], AxiosError> 타입의 옵션 객체 (선택 사항)
 * @returns CustomQueryResult<ChannelDto[]> 타입의 쿼리 결과 객체
 */

export function useChannelsQuery(
  filterOptions: {
    searchText?: string;
    isBiz?: boolean;
    isDelete?: boolean;
    isActive?: boolean;
    page?: number;
    size?: number;
    sort?: string[];
  } = {},

  options?: UseQueryOptions<ChannelsResponse, AxiosError>,
): CustomQueryResult<ChannelsResponse> {
  const fetchChannels = async () => {
    const query = { ...filterOptions };

    const response = await adminService.getAllChannels(query);
    const pageInfo = parsePageInfoFromHeaders(response.headers);

    return { channels: response.data, pageInfo };
  };

  return useQuery<ChannelsResponse, AxiosError>(
    [queryKeys.channels, filterOptions],
    fetchChannels,
    options,
  );
}

/**
 * 특정 채널 정보를 가져오는 커스텀 훅
 *
 * @param channelId 가져올 채널의 ID
 * @param options UseQueryOptions<ChannelDto, AxiosError> 타입의 옵션 객체 (선택 사항)
 * @returns CustomQueryResult<ChannelDto> 타입의 쿼리 결과 객체
 */
export function useChannelQuery(
  channelId: number,
  options?: UseQueryOptions<ChannelDto, AxiosError>,
): CustomQueryResult<ChannelDto> {
  const fetchChannel = async () => {
    const response = await adminService.getChannel2(channelId);
    return response.data;
  };

  return useQuery<ChannelDto, AxiosError>(
    queryKeys.channelById(channelId),
    fetchChannel,
    options,
  );
}

/**
 * 비즈 채널 전환
 *
 */
export function useConvertToBizMutation(
  options?: UseMutationOptions<ConvertToBizData, AxiosError, SimpleChannelDto>,
): UseMutationResult<ConvertToBizData, AxiosError, SimpleChannelDto> {
  const convertToBiz = async (data: SimpleChannelDto) => {
    return await adminService.convertToBiz(data);
  };

  return useMutation(convertToBiz, options);
}
