import { AxiosError } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { AdminService } from 'services/AdminService';
import { HttpClient } from 'services/http-client';
import { queryKeys } from './queryKey';
import { ChannelAuthenticationDto } from 'services/data-contracts';
import parsePageInfoFromHeaders from '../utils/parsePageInfoFromHeaders';
import PageInfo from '../types/PageInfo';

const adminService = new AdminService(new HttpClient());

// Define a new type for the custom query result to include the UseQueryResult for ChannelDto[].
type CustomQueryResult<T> = UseQueryResult<T, AxiosError>;

interface RequestedChannelsResponse {
  requestedChannels: ChannelAuthenticationDto[];
  pageInfo: PageInfo;
}

/**
 * 채널 목록을 가져오는 커스텀 훅
 *
 * @param options UseQueryOptions<ChannelDto[], AxiosError> 타입의 옵션 객체 (선택 사항)
 * @returns CustomQueryResult<ChannelDto[]> 타입의 쿼리 결과 객체
 */

export function useRequestedAuthChannelsQuery(
  filterOptions: {
    searchText?: string;
    page?: number;
    size?: number;
    sort?: string[];
  } = {},

  options?: UseQueryOptions<RequestedChannelsResponse, AxiosError>,
): CustomQueryResult<RequestedChannelsResponse> {
  const fetchChannels = async () => {
    const query = {
      searchText: filterOptions.searchText || '', // 기본값 또는 빈 문자열로 설정
      page: filterOptions.page,
      size: filterOptions.size,
      sort: filterOptions.sort,
    };

    const response = await adminService.getRequestedChannels(query);
    const pageInfo = parsePageInfoFromHeaders(response.headers);

    return { requestedChannels: response.data, pageInfo };
  };

  return useQuery<RequestedChannelsResponse, AxiosError>(
    [queryKeys.requestedChannels, filterOptions],
    fetchChannels,
    options,
  );
}

// export function useRequestedAuthChannelsQuery(
//   filterOptions: {
//     searchText?: string;
//     page?: number;
//     size?: number;
//     sort?: string[];
//   } = {},
//   options?: UseQueryOptions<RequestedChannelsResponse, AxiosError>,
// ): CustomQueryResult<RequestedChannelsResponse> {
//   const fetchChannels = async () => {
//     try {
//       const query = {
//         searchText: filterOptions.searchText || '', // 기본값 또는 빈 문자열로 설정
//         page: filterOptions.page,
//         size: filterOptions.size,
//         sort: filterOptions.sort,
//       };

//       const response = await adminService.getRequestedChannels(query);
//       const pageInfo = parsePageInfoFromHeaders(response.headers);

//       return { requestedChannels: response.data, pageInfo };
//     } catch (error) {
//       throw error.response?.data || error;
//     }
//   };

//   return useQuery<RequestedChannelsResponse, AxiosError>(
//     [queryKeys.requestedChannels, filterOptions],
//     fetchChannels,
//     options,
//   );
// }

// interface RedirectUriQueryResult {
//   data?: GetRedirectUriData;
//   error?: AxiosError<unknown, any> | null;
// }

// export const useRedirectUriQuery = (authKey: string) => {
//   return useQuery<RedirectUriQueryResult>(['redirectUri', authKey], () =>
//     adminService.getRedirectUri(authKey),
//   );
// };

// export function useRedirectUriQuery(
//   authKey: string,
//   options?: UseQueryOptions<GetRedirectUriData, AxiosError>,
// ): CustomQueryResult<GetRedirectUriData> {
//   const fetchRedirectUri = async () => {
//     const response = await adminService.getRedirectUri(authKey);
//     return response.data;
//   };

//   return useQuery<GetRedirectUriData, AxiosError>(
//     queryKeys.redirectUri(authKey),
//     fetchRedirectUri,
//     options,
//   );
// }

// /**
//  * 인증 RedirectUri 가져오기
//  *
//  */
// // 기존의 useRedirectUriQuery를 커스텀 훅으로 변경
// export function useRedirectUriQuery(authKey: string) {
//   const { data, error } = useQuery<GetRedirectUriData, AxiosError>(
//     queryKeys.redirectUri(authKey),
//     async () => {
//       const response = await adminService.getRedirectUri(authKey);
//       return response.data;
//     },
//   );

//   return { data, error };
// }
