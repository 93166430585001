export const SocialServiceList: string[] = [
  'Facebook',
  'Twitter',
  'Instagram',
  'Thread',
  'LinkedIn',
  // 'Pinterest',
  // 'Snapchat',
  // 'Reddit',
  // 'TikTok',
  // 'WhatsApp',
  'YouTube',
];
