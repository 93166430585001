import { makeAutoObservable } from 'mobx';
import { AuthController as AuthService } from 'services/AuthController';
import { LoginDto } from 'services/data-contracts';
import { HttpClient } from 'services/http-client';
import { v4 as uuidv4 } from 'uuid';

class AuthStore {
  authService = new AuthService(
    new HttpClient({ baseURL: process.env.REACT_APP_API_DOMAIN }),
  );

  accessToken: string | null = localStorage.getItem('accessToken');
  refreshToken: string | null = localStorage.getItem('refreshToken');

  constructor() {
    makeAutoObservable(this);
  }

  async login(email: string, password: string) {
    const loginDto: LoginDto = {
      username: email,
      password: password,
    };

    const deviceToken = this.getUUID();
    const pushToken = this.getPushToken();

    try {
      const response = await this.authService.login(loginDto, {
        headers: {
          'device-model': 'WEB',
          'os-type': 'Browser', //navigator.userAgent,
          'os-version': 'v3',
          'device-token': deviceToken,
          'push-token': pushToken,
        },
      });

      localStorage.setItem('accessToken', response.data.accessToken!);
      localStorage.setItem('refreshToken', response.data.refreshToken!);
      localStorage.setItem('deviceToken', deviceToken);
    } catch (error) {
      console.log(error);
      throw new Error(
        '로그인을 실패했습니다. 올바른 이메일과 비밀번호를 입력하세요.',
      );
    }
  }

  // setAccessToken(accessToken: string | null) {
  //     this.accessToken = accessToken;
  //     if (accessToken) {
  //         localStorage.setItem('accessToken', accessToken);
  //     } else {
  //         localStorage.removeItem('accessToken');
  //     }
  // }

  // setRefreshToken(refreshToken: string | null) {
  //     this.refreshToken = refreshToken;
  //     if (refreshToken) {
  //         localStorage.setItem('refreshToken', refreshToken);
  //     } else {
  //         localStorage.removeItem('refreshToken');
  //     }
  // }

  isAuthenticated() {
    return (
      !!localStorage.getItem('accessToken') &&
      !!localStorage.getItem('refreshToken')
    );
  }
  logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  getUUID() {
    const uuid = localStorage.getItem(`uuid`);
    if (uuid === null) {
      const uuid = uuidv4();
      return uuid;
    }
    return uuid;
  }

  getPushToken() {
    return localStorage.getItem(`pushToken`) || '';
  }
}

export const authStore = new AuthStore();
export const useAuthStore = () => authStore;
