import * as React from 'react';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useUsersQuery } from '../queries/useUser';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from '@mui/material';
import UserFilter from '../components/UserFilter';
import UserDetailsPopup from './UserDetailsPopup';

const PAGE_SIZE = 10;

const UserPage = () => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'username',
      headerName: '이메일',
      width: 288,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'channelCount',
      headerName: '보유채널',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.channelCount} 채널`,
    },
    {
      field: 'loginType',
      headerName: '가입유형',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.loginType === 'Social') {
          return `${params.row.provider}`;
        } else {
          return `${params.row.loginType}`;
        }
      },
    },
    {
      field: 'signupDate',
      headerName: '가입일',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridValueGetterParams) => {
        const signupDateUTC = params.row.signupDate;
        const signupDateKST = new Date(signupDateUTC).toLocaleString('ko-KR', {
          timeZone: 'Asia/Seoul',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
        });
        return signupDateKST;
      },
    },
    {
      field: 'isDormant',
      headerName: '휴면여부',
      width: 130,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.isDormant ? 'O' : '-';
      },
    },
    {
      field: 'isWithdrawn',
      headerName: '탈퇴여부',
      width: 130,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.isWithdrawn ? 'O' : '-';
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => (
        <Button
          onClick={() => {
            handleUserClick(params.row.id);
          }}
          color="info"
          variant="outlined"
        >
          상세보기
        </Button>
      ),
    },
  ];

  // const [, startTransition] = React.useTransition();
  const [filter, setFilter] = React.useState({
    search: '',
    loginType: '',
    dormantOnly: false,
    withdrawnOnly: false,
  });

  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({
      page: 0,
      pageSize: PAGE_SIZE,
    });

  const [sortModel, setSortModel] = React.useState<string[]>(['id,desc']);

  // 선택한 사용자 정보
  const [selectedUserId, setSelectedUserId] = React.useState<number>(0);

  // 팝업 레이어 열림/닫힘 상태
  const [isPopupOpen, setPopupOpen] = React.useState(false);

  const { data, isLoading, error } = useUsersQuery({
    userName: filter.search,
    page: paginationModel.page,
    size: paginationModel.pageSize,
    sort: sortModel,
  });

  // 데이터 및 페이지 정보 추출
  const users = data?.users ?? [];
  const pageInfo = data?.pageInfo;

  // 팝업 열기 함수
  const openPopup = (userId: number) => {
    if (userId > 0) {
      setSelectedUserId(userId);
      setPopupOpen(true);
    }
  };

  // 팝업 닫기 함수
  const closePopup = () => {
    setSelectedUserId(0);
    setPopupOpen(false);
  };

  // 필터 변경 핸들러
  const handleFilterChange = (newFilter: any) => {
    // 필터 및 페이지 초기화
    setPaginationModel({ page: 0, pageSize: PAGE_SIZE });
    setFilter(newFilter);
  };

  // 페이지 변경 핸들러
  const handlePaginationModelChange = (
    paginationModel: GridPaginationModel,
  ) => {
    setPaginationModel(paginationModel);
  };

  // 정렬 변경 핸들러
  const handleSortModelChange = (sortModel: GridSortModel) => {
    const transformedSort: string[] = sortModel.map((item) => {
      const sortOrder = item.sort === 'asc' ? 'asc' : 'desc';
      return `${item.field},${sortOrder}`;
    });
    setSortModel(transformedSort);
  };

  // 사용자 정보 클릭 핸들러
  const handleUserClick = (userId: number) => {
    openPopup(userId);
  };

  // 에러 처리
  if (error) {
    toast.error(`Error: ${error.message}`);
  }

  //로딩 중인 경우 로딩 메시지 표시
  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <StyledContainer>
      <UserFilter filter={filter} onFilterChange={handleFilterChange} />
      {/* <React.Suspense fallback={<div>Loading...</div>}> */}
      {users && (
        <StyledDataGrid
          rows={users}
          disableColumnMenu
          columns={columns}
          rowCount={pageInfo?.totalCount || 0}
          loading={isLoading}
          pageSizeOptions={[PAGE_SIZE]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          // onRowClick={(params) => handleUserClick(params.row)}
        />
      )}

      {/* 팝업 컴포넌트*/}
      {isPopupOpen && (
        <UserDetailsPopup
          userId={selectedUserId}
          open={isPopupOpen}
          onClose={closePopup}
        />
      )}

      {/* </React.Suspense> */}
    </StyledContainer>
  );
};

export default UserPage;

const StyledContainer = styled.div`
  height: 650px;
  width: 1350px;
  padding: 20px;
`;

const StyledDataGrid = styled(DataGrid)`
  font-family: var(--font-nanumfont) !important;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .MuiDataGrid-columnHeader {
    background-color: #f0f0f0; /* 헤더 배경색 */
    font-weight: bold;
  }

  .MuiDataGrid-row {
    &:nth-of-type(odd) {
      background-color: #f8f8f8; /* 홀수 줄 배경색 */
    }

    &:hover {
      background-color: #f0f0f0; /* 마우스 오버시 배경색 */
    }
  }

  .MuiDataGrid-cell {
    border: none; /* 셀 테두리 제거 */
  }
`;
