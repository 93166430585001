import React, { useEffect, useState, useRef } from 'react';
import { FileDto } from 'services/data-contracts';
import { CustomFile, CropType } from '../../types/CommonTypes'
import { convertBase64Files2 } from './imageUtil'

import SingleImage from './SingleImage';

/**
 * @description 이미지 선택부터 크롭까지 제공한다.
 * @params type : copper, single, multiple
 * @returns
 */
const ImageUploader = ({
    children,
    useCircularCrop,
    initialValue,
    type,
    useCrop = true,
    attachType,
    modalType = 'bottomSheet',
    onSelected,
}: {
    children?: React.ReactNode | React.ReactNode[];
    useCircularCrop?: boolean;
    useCrop?: boolean;
    initialValue?: CustomFile | CustomFile[];
    type: 'copper' | 'single' | 'multiple';
    origin?: 'card' | 'video' | undefined;
    attachType?: 'video' | 'image' | 'media';
    currentImage?: FileDto;
    currentColor?: string;
    show?: boolean;
    cropType?: CropType;
    /**
     * @deprecated
     */
    useColor?: boolean;
    cropSize?: { width: number; height: number };
    multipleUpload?: boolean;
    modalType?: 'modal' | 'bottomSheet';
    onSelected: (image: CustomFile | CustomFile[] | undefined) => void;
}) => {
    const onChangeFile = useRef<any>(null);

    const files = useRef<any>(null);
    const [accept, setAccept] = useState<string>('');
    const [images, setImages] = useState<CustomFile | CustomFile[]>();

    useEffect(() => {
        switch (attachType) {
            case 'image':
                setAccept('.jpg, .jpeg, .png, .gif');
                break;
            case 'video':
                setAccept('.mp4');
                break;
            case 'media':
                setAccept('.jpg, .jpeg, .png, .gif, .mp4');
                break;
            default:
                setAccept('');
                break;
        }
    }, [attachType]);

    useEffect(() => {
        if (initialValue) {
            setImages(initialValue);
        }
    }, [initialValue]);

    return (
        <>
            <div
                onClick={(e: any) => {
                    onChangeFile.current.click(e);
                }}
            >
                {children ? <>{children}</> : <><SingleImage image={images as CustomFile} /></>}
            </div>
            <input
                type="file"
                multiple={type === 'multiple'}
                accept={accept}
                id="file"
                ref={onChangeFile}
                onInput={async () => {
                    files.current = onChangeFile.current.files;
                    const converted = (await convertBase64Files2(files.current)) as unknown as CustomFile[];
                    const c = type === 'multiple' ? converted : converted[0];
                    if (c) {
                        setImages(c);
                        onSelected(c);
                    }
                }}
                style={{ display: 'none' }}
            />
        </>
    );
};

export default ImageUploader;
