import { AxiosError } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { AdminService } from 'services/AdminService';
import { HttpClient } from 'services/http-client';
import { queryKeys } from './queryKey';
import { AddCoinData, AddCoinDto, CoinEventDto } from 'services/data-contracts';
import parsePageInfoFromHeaders from '../utils/parsePageInfoFromHeaders';
import PageInfo from '../types/PageInfo';

const adminService = new AdminService(new HttpClient());

// Define a new type for the custom query result to include the UseQueryResult for ChannelDto[].
type CustomQueryResult<T> = UseQueryResult<T, AxiosError>;

interface CoinsResponse {
  coins: CoinEventDto[];
  pageInfo: PageInfo;
}

/**
 * 코인 목록을 가져오는 커스텀 훅
 *
 */
export function useCoinHistoryQuery(
  channelId: number,
  filterOptions: {
    page?: number;
    size?: number;
    sort?: string[];
  } = {},

  options?: UseQueryOptions<CoinsResponse, AxiosError>,
): CustomQueryResult<CoinsResponse> {
  const fetchCoinHistory = async () => {
    const query = { ...filterOptions };

    const response = await adminService.getCoinHistory(channelId, query);
    const pageInfo = parsePageInfoFromHeaders(response.headers);

    return { coins: response.data, pageInfo };
  };

  return useQuery<CoinsResponse, AxiosError>(
    [queryKeys.coins(channelId), filterOptions],
    fetchCoinHistory,
    options,
  );
}

/**
 * 코인 지급
 *
 */
export function useAddCoinMutation(
  options?: UseMutationOptions<AddCoinData, AxiosError, AddCoinDto>,
): UseMutationResult<AddCoinData, AxiosError, AddCoinDto> {
  const convertToBiz = async (data: AddCoinDto) => {
    return await adminService.addCoin(data);
  };

  return useMutation(convertToBiz, options);
}
