import { QueryClient, QueryClientProvider } from 'react-query';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import AppRouter from 'AppRouter';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'pages/ErrorFallback';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const queryErrorHandler = (error: any) => {
  // toast(`데이터를 가져오지 못했습니다! ${error.message}`);
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: queryErrorHandler,
      retry: 0,
      refetchOnWindowFocus: false,
      // refetchOnMount: true,
    },
  },
});

// 새로운 폰트 스타일 정의
const customTheme = createTheme({
  typography: {
    fontFamily: 'nanumfont, sans-serif', // 원하는 폰트로 변경
    fontSize: 14,
  },
});

function App() {
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={customTheme}>
            <AppRouter />
            <ToastContainer
              position="bottom-left"
              autoClose={2500}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              transition={Slide}
              style={{
                zIndex: 100000002,
              }}
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </ThemeProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  );
}
export default App;
