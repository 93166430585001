import React, { useState } from 'react';
// import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import { useQueryErrorResetBoundary } from 'react-query';
// import { ErrorBoundary } from 'react-error-boundary';
// import ErrorFallback from './ErrorFallback';
import { useAuthStore } from 'stores/AuthStore';
import styled from 'styled-components';
import logoImage from '../assets/images/logo.png';
import { toast } from 'react-toastify';

const LoginPage = () => {
  // const { reset } = useQueryErrorResetBoundary();
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // const [email, setEmail] = useState('suminpark80@gmail.com');
  // const [password, setPassword] = useState('a123456789');

  const handleLogin = async () => {
    try {
      await authStore.login(email, password);
      navigate('/dashboard');
    } catch (error) {
      toast.error(
        '로그인을 실패했습니다. 올바른 이메일과 비밀번호를 입력하세요.',
      );
    }
  };

  return (
    // <ErrorBoundary FallbackComponent={ErrorFallback} onReset={reset}>
    <LoginPageWrapper>
      <LogoImage src={logoImage} alt="Logo" />
      <LoginForm>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={handleLogin}>Login</Button>
      </LoginForm>
    </LoginPageWrapper>
    // </ErrorBoundary>
  );
};

const LoginPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LogoImage = styled.img`
  width: 100px;
  height: auto;
  margin-bottom: 20px;
`;

const LoginForm = styled.div`
  width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  width: 94%;
  height: 40px;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  background-color: #009247;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #007f3f;
  }
`;

export default LoginPage;
