import { ReactNode } from 'react';
import DashboardPage from 'pages/DashboardPage';
import UserPage from 'pages/UserPage';
import ChannelPage from 'pages/channel/ChannelPage';
import MagazinePage from 'pages/magazine/MagazinePage';
import MagazinCreatePopup from 'pages/magazine/MagazineCreatePopup';
import ChannelAuthenticationPage from 'pages/channel/ChannelAuthenticationPage';

export interface MenuItem {
  label: string;
  path: string;
  component: ReactNode;
  isMenu?: boolean;
}

const menuItems: MenuItem[] = [
  {
    label: '대시보드',
    path: '/dashboard',
    component: <DashboardPage />,
    isMenu: true,
  },
  { label: '회원관리', path: '/users', component: <UserPage />, isMenu: true },
  {
    label: '채널관리',
    path: '/channels',
    component: <ChannelPage />,
    isMenu: true,
  },
  {
    label: '채널인증',
    path: '/channels/request',
    component: <ChannelAuthenticationPage />,
    isMenu: true,
  },
  {
    label: '매거진관리',
    path: '/magazines',
    component: <MagazinePage />,
    isMenu: true,
  },
  {
    label: '매거진작성',
    path: '/magazines/create',
    component: <MagazinCreatePopup />,
  },
  {
    label: '매거진수정',
    path: '/magazines/:magazineid/modify',
    component: <MagazinCreatePopup />,
  },
];

export default menuItems;
