export const queryKeys = {
  users: ['users'] as const,
  userById: (userId: number) => ['users', userId] as const,

  channels: ['channels'] as const,
  channelById: (channelId: number) => ['channels', channelId] as const,

  requestedChannels: ['requestedChannels'] as const,
  redirectUri: (authKey: string) => [authKey] as const,
  requestedChannelByAuthKey: (authKey: string) =>
    ['requestedChannels', authKey] as const,

  magazines: ['magazines'] as const,
  magazineById: (magazineId: number) => ['magazines', magazineId] as const,

  // coins: ['coins'] as const,
  coins: (channelId: number) => ['coins', channelId] as const,
};
