/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateMagazineData,
  DeleteMagazineData,
  GetMagazineData,
  GetMagazines1Data,
  MagazineDto,
  UpdateMagazineData,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class AdminMagazineService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags AdminMagazineService
   * @name GetMagazine
   * @summary 관리자 매거진 정보
   * @request GET:/v2/admin/magazines/{magazineId}
   * @secure
   * @response `200` `GetMagazineData` OK
   */
  getMagazine = (magazineId: number, params: RequestParams = {}) =>
    this.http.request<GetMagazineData, any>({
      path: `/v2/admin/magazines/${magazineId}`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * @description 매거진 수정
   *
   * @tags AdminMagazineService
   * @name UpdateMagazine
   * @summary 매거진 수정
   * @request PUT:/v2/admin/magazines/{magazineId}
   * @secure
   * @response `200` `UpdateMagazineData` OK
   */
  updateMagazine = (magazineId: number, data: MagazineDto, params: RequestParams = {}) =>
    this.http.request<UpdateMagazineData, any>({
      path: `/v2/admin/magazines/${magazineId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 매거진 삭제
   *
   * @tags AdminMagazineService
   * @name DeleteMagazine
   * @summary 매거진 삭제
   * @request DELETE:/v2/admin/magazines/{magazineId}
   * @secure
   * @response `200` `DeleteMagazineData` OK
   */
  deleteMagazine = (magazineId: number, params: RequestParams = {}) =>
    this.http.request<DeleteMagazineData, any>({
      path: `/v2/admin/magazines/${magazineId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags AdminMagazineService
   * @name GetMagazines1
   * @summary 관리자 매거진 목록
   * @request GET:/v2/admin/magazines
   * @secure
   * @response `200` `GetMagazines1Data` OK
   */
  getMagazines1 = (
    query?: {
      /** 검색어 */
      searchText?: string;
      /** 배너여부 */
      isBanner?: boolean;
      /** 활성여부 */
      isActive?: boolean;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetMagazines1Data, any>({
      path: `/v2/admin/magazines`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description 매거진 생성
   *
   * @tags AdminMagazineService
   * @name CreateMagazine
   * @summary 매거진 생성
   * @request POST:/v2/admin/magazines
   * @secure
   * @response `200` `CreateMagazineData` OK
   */
  createMagazine = (data: MagazineDto, params: RequestParams = {}) =>
    this.http.request<CreateMagazineData, any>({
      path: `/v2/admin/magazines`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
