import { useNavigate } from 'react-router-dom';
// import './ErrorFallback.scss';
import { getErrorMessage } from './ErrorMessage';
import { FallbackProps } from 'react-error-boundary';
import type { AxiosError } from 'axios';

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const responseError = error as AxiosError;

  const status = responseError.response?.status!;

  console.log(`ErrorFallback : ${status}`);

  const navigate = useNavigate();
  const { title, content } = getErrorMessage(status);
  const isNotAuthorized = status === 401 || status === 403;
  const buttonMessage = isNotAuthorized ? '로그인' : '새로고침';

  const onClickHandler = () => {
    console.log(`isNotAuthorized : ${isNotAuthorized}`);
    if (isNotAuthorized) {
      navigate('/login');
    } else {
      resetErrorBoundary();
    }
  };

  return (
    <div className="error-fallback-wrapper">
      <div className="inner">
        <h2 className="title">{title}</h2>
        <p className="content">{content}</p>
        <button type="button" onClick={onClickHandler}>
          {buttonMessage}
        </button>
      </div>
    </div>
  );
}

export default ErrorFallback;
