import { CustomFile } from "types/CommonTypes";
import Resizer from 'react-image-file-resizer';

export const convertBase64Files2 = async (e: any) => {
    if (e === null) {
        return;
    }
    const files = Array.from(e);
    return Promise.all(
        await files?.map(async (file: any) => {
            const base64Image = await convertToBase64(file);
            // const fileObject = await fetch(base64Image as string).then((res) => res.blob());
            const blob = (await resizeImage(file, { width: 900, height: 4000 })) as Blob;
            return {
                publicUrl: base64Image,
                blob: blob || file,
                contentType: blob.type,
            } as CustomFile;
        }),
    );
};


const convertToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = (e: any) => {
            // resolve(fileReader.result);
            resolve(URL.createObjectURL(file));
            const tt = ';';
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

const resizeImage = (file: any, imageSize: any) =>
    new Promise((resolve) => {
        const t = file.type.split('/');
        const type = t.length === 1 ? file.type : t[1];
        Resizer.imageFileResizer(
            file,
            imageSize.width,
            imageSize.height,
            type,
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            'file',
        );
    });

