import * as React from 'react';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { useRequestedAuthChannelsQuery } from 'queries/useChannelAuthentication';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ChannelAuthenticationFilter from 'components/ChannelAuthenticationFilter';
// import ChannelFilter from '../components/ChannelFilter';
import ChannelDetailsPopup from './ChannelDetailsPopup';

const PAGE_SIZE = 10;

const ChannelAuthenticationPage = () => {
  const columns: GridColDef[] = [
    // {
    //   field: 'id',
    //   headerName: 'ID',
    //   width: 100,
    //   align: 'center',
    //   headerAlign: 'center',
    //   sortable: true,
    // },
    {
      field: 'createDate',
      headerName: '인증요청일',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      sortable: true,
      valueGetter: (params) => {
        const createDate = new Date(params.value).toLocaleString('ko-KR', {
          timeZone: 'Asia/Seoul',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
        });
        return createDate;
      },
    },
    {
      field: 'channelName',
      headerName: '채널명',
      width: 220,
      align: 'center',
      headerAlign: 'center',
      sortable: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.channel.channelName;
      },
    },
    // {
    //   field: 'authKey',
    //   headerName: '인증키',
    //   width: 200,
    //   align: 'center',
    //   headerAlign: 'center',
    //   sortable: true,
    // },
    {
      field: 'socialType',
      headerName: '소셜구분',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      sortable: true,
    },
    {
      field: 'socialUsername',
      headerName: '소셜계정',
      width: 230,
      align: 'center',
      headerAlign: 'center',
      sortable: true,
    },

    {
      field: 'redirectUri',
      headerName: '인증주소',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => (
        <Button
          onClick={() =>
            handleRedirectUriCopyClick(
              params.row.authKey,
              params.row.channel.id,
            )
          }
          color="secondary"
          variant="outlined"
        >
          복사하기
        </Button>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => (
        <Button
          onClick={() => {
            handleChannelClick(params.row.channel.id);
          }}
          color="info"
          variant="outlined"
        >
          상세보기
        </Button>
      ),
    },
  ];

  const [filter, setFilter] = React.useState({
    searchText: '',
    bizOnly: undefined,
    deleteOlny: undefined,
  });

  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({
      page: 0,
      pageSize: PAGE_SIZE,
    });

  const [sortModel, setSortModel] = React.useState<string[]>(['id,desc']);

  // 선택한 채널 정보
  const [selectedChannelId, setSelectedChannelId] = React.useState<number>(0);

  // 팝업 레이어 열림/닫힘 상태
  const [isPopupOpen, setPopupOpen] = React.useState(false);

  const { data, isLoading, error } = useRequestedAuthChannelsQuery({
    searchText: filter.searchText,
    page: paginationModel.page,
    size: paginationModel.pageSize,
    sort: sortModel,
  });

  // 데이터 및 페이지 정보 추출
  const channels = data?.requestedChannels ?? [];
  const pageInfo = data?.pageInfo;

  // 팝업 열기 함수
  const openPopup = (channelId: number) => {
    if (channelId > 0) {
      setSelectedChannelId(channelId);
      setPopupOpen(true);
    }
  };

  // 팝업 닫기 함수
  const closePopup = () => {
    //console.log('closed');

    // queryClient.invalidateQueries(queryKeys.channels);
    setSelectedChannelId(0);
    setPopupOpen(false);
  };

  // 필터 변경 핸들러
  const handleFilterChange = (newFilter: any) => {
    // 필터 및 페이지 초기화
    setPaginationModel({ page: 0, pageSize: PAGE_SIZE });
    setFilter(newFilter);
  };

  // 페이지 변경 핸들러
  const handlePaginationModelChange = (
    paginationModel: GridPaginationModel,
  ) => {
    setPaginationModel(paginationModel);
  };

  // 정렬 변경 핸들러
  const handleSortModelChange = (sortModel: GridSortModel) => {
    const transformedSort: string[] = sortModel.map((item) => {
      const sortOrder = item.sort === 'asc' ? 'asc' : 'desc';
      return `${item.field},${sortOrder}`;
    });
    setSortModel(transformedSort);
  };

  // 채널 정보 클릭 핸들러
  const handleChannelClick = (channelId: number) => {
    openPopup(channelId);
  };

  const handleRedirectUriCopyClick = async (
    authKey: string,
    channelId: number,
  ) => {
    const domain = process.env.REACT_APP_DOMAIN;
    const url = `${domain}authenticate-channel?authKey=${authKey}&channelId=${channelId}`;

    navigator.clipboard.writeText(url);
    toast.success(`클립보드에 복사되었습니다.`);
  };

  // 에러 처리
  if (error) {
    toast.error(`Error: ${error.message}`);
  }

  //로딩 중인 경우 로딩 메시지 표시
  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <StyledContainer>
      <ChannelAuthenticationFilter
        filter={filter}
        onFilterChange={handleFilterChange}
      />
      {/* <React.Suspense fallback={<div>Loading...</div>}> */}
      {channels && (
        <StyledDataGrid
          rows={channels}
          disableColumnMenu
          columns={columns}
          rowCount={pageInfo?.totalCount || 0}
          loading={isLoading}
          pageSizeOptions={[PAGE_SIZE]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          // onRowClick={(params) => handleChannelClick(params.row)}
        />
      )}

      {/* 팝업 컴포넌트*/}
      {isPopupOpen && (
        <ChannelDetailsPopup
          channelId={selectedChannelId}
          open={isPopupOpen}
          onClose={closePopup}
        />
      )}

      {/* </React.Suspense> */}
    </StyledContainer>
  );
};

export default ChannelAuthenticationPage;

const StyledContainer = styled.div`
  height: 650px;
  width: 1092px;
  padding: 20px;
`;

const StyledDataGrid = styled(DataGrid)`
  font-family: var(--font-nanumfont) !important;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .MuiDataGrid-columnHeader {
    background-color: #f0f0f0; /* 헤더 배경색 */
    font-weight: bold;
  }

  .MuiDataGrid-row {
    &:nth-of-type(odd) {
      background-color: #f8f8f8; /* 홀수 줄 배경색 */
    }

    &:hover {
      background-color: #f0f0f0; /* 마우스 오버시 배경색 */
    }
  }

  .MuiDataGrid-cell {
    border: none; /* 셀 테두리 제거 */
  }
`;
