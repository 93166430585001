import axios, { InternalAxiosRequestConfig } from 'axios';
import { isExpired } from 'react-jwt';

const RefreshToken = async (
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const deviceToken = localStorage.getItem('deviceToken');

  if (accessToken && refreshToken) {
    console.log('RefreshToken');
    const expired = isExpired(accessToken as string);

    if (!expired) {
      setAuthorizationHeader(config, accessToken);
      return config;
    }

    console.log('토큰 재발급 시작');

    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_DOMAIN}v2/auth/refresh-token`,
      data: {
        accessToken: String(accessToken),
        refreshToken: String(refreshToken),
        deviceToken: String(deviceToken),
      },
      headers: {
        'Content-Type': 'application/json',
        'device-model': 'pc',
        'os-type': 'window',
        'os-version': '1.0',
        'device-token': deviceToken,
      },
    })
      .then((response) => {
        console.log('토큰 재발급 성공', response.data.accessToken);

        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);

        setAuthorizationHeader(config, response.data.accessToken);
      })
      .catch((response) => {
        console.log('토큰 재발급 실패');

        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      });
  }

  return config;
};

function setAuthorizationHeader(
  config: InternalAxiosRequestConfig,
  accessToken: string,
) {
  if (config.headers) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
}

// // eslint-disable-next-line class-methods-use-this
// const getUUID = () => {
//   // const email = getUserEmail();
//   // const uuid = localStorage.getItem(`${email}_uuid`);
//   const uuid = localStorage.getItem(`uuid`);
//   if (uuid === null) {
//     const uuid = uuidv4();
//     localStorage.setItem(`uuid`, uuid);
//     return uuid;
//   }
//   return uuid;
// };

const refreshErrorHandle = (err: any): void => {
  console.log('리플래쉬에러핸들');
  localStorage.removeItem('refreshToken');
};

export { RefreshToken, refreshErrorHandle };
