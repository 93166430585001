/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AcceptAdReportData,
  AdBlockDto,
  AdReportAcceptDto,
  AddCoinData,
  AddCoinDto,
  BlockData,
  CoinPolicyDto,
  ConvertToBizData,
  GetAdsData,
  GetAllChannelsData,
  GetChannel2Data,
  GetCoinHistoryData,
  GetCoinPoliciesData,
  GetRedirectUriData,
  GetReportedAdsData,
  GetRequestedChannelsData,
  GetUser1Data,
  GetUsersData,
  SimpleChannelDto,
  UpdateCoinPolicyData,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class AdminService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 코인 지급
   *
   * @tags AdminService
   * @name AddCoin
   * @summary 코인 지급
   * @request POST:/v2/admin/coin/add-coin
   * @secure
   * @response `200` `AddCoinData` OK
   */
  addCoin = (data: AddCoinDto, params: RequestParams = {}) =>
    this.http.request<AddCoinData, any>({
      path: `/v2/admin/coin/add-coin`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Biz 채널 전환
   *
   * @tags AdminService
   * @name ConvertToBiz
   * @summary Biz 채널 전환
   * @request POST:/v2/admin/biz
   * @secure
   * @response `200` `ConvertToBizData` OK
   */
  convertToBiz = (data: SimpleChannelDto, params: RequestParams = {}) =>
    this.http.request<ConvertToBizData, any>({
      path: `/v2/admin/biz`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 코인 정책 목록
   *
   * @tags AdminService
   * @name GetCoinPolicies
   * @summary 코인 정책 목록
   * @request GET:/v2/admin/coin/policies
   * @secure
   * @response `200` `GetCoinPoliciesData` OK
   */
  getCoinPolicies = (params: RequestParams = {}) =>
    this.http.request<GetCoinPoliciesData, any>({
      path: `/v2/admin/coin/policies`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * @description 코인 정책 수정
   *
   * @tags AdminService
   * @name UpdateCoinPolicy
   * @summary 코인 정책 수정
   * @request PATCH:/v2/admin/coin/policies
   * @secure
   * @response `200` `UpdateCoinPolicyData` OK
   */
  updateCoinPolicy = (data: CoinPolicyDto, params: RequestParams = {}) =>
    this.http.request<UpdateCoinPolicyData, any>({
      path: `/v2/admin/coin/policies`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 신고된 광고 승인/반려
   *
   * @tags AdminService
   * @name AcceptAdReport
   * @summary 신고된 광고 승인/반려
   * @request PATCH:/v2/admin/ads/{adId}/report
   * @secure
   * @response `200` `AcceptAdReportData` OK
   */
  acceptAdReport = (adId: number, data: AdReportAcceptDto, params: RequestParams = {}) =>
    this.http.request<AcceptAdReportData, any>({
      path: `/v2/admin/ads/${adId}/report`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 광고 제재
   *
   * @tags AdminService
   * @name Block
   * @summary 광고 제재
   * @request PATCH:/v2/admin/ads/{adId}/block
   * @secure
   * @response `200` `BlockData` OK
   */
  block = (adId: number, data: AdBlockDto, params: RequestParams = {}) =>
    this.http.request<BlockData, any>({
      path: `/v2/admin/ads/${adId}/block`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 회원 목록
   *
   * @tags AdminService
   * @name GetUsers
   * @summary 회원 목록
   * @request GET:/v2/admin/users
   * @secure
   * @response `200` `GetUsersData` OK
   */
  getUsers = (
    query?: {
      userName?: string;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["signupDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetUsersData, any>({
      path: `/v2/admin/users`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description 회원 정보
   *
   * @tags AdminService
   * @name GetUser1
   * @summary 회원 정보
   * @request GET:/v2/admin/users/{userId}
   * @secure
   * @response `200` `GetUser1Data` OK
   */
  getUser1 = (userId: number, params: RequestParams = {}) =>
    this.http.request<GetUser1Data, any>({
      path: `/v2/admin/users/${userId}`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags AdminService
   * @name GetRequestedChannels
   * @summary 인증요청 채널목록
   * @request GET:/v2/admin/requested-auth-channels
   * @secure
   * @response `200` `GetRequestedChannelsData` OK
   */
  getRequestedChannels = (
    query: {
      searchText: string;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetRequestedChannelsData, any>({
      path: `/v2/admin/requested-auth-channels`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags AdminService
   * @name GetRedirectUri
   * @summary 인증요청 RedirectUri 생성
   * @request GET:/v2/admin/requested-auth-channels/{authKey}/url
   * @secure
   * @response `200` `GetRedirectUriData` OK
   */
  getRedirectUri = (authKey: string, params: RequestParams = {}) =>
    this.http.request<GetRedirectUriData, any>({
      path: `/v2/admin/requested-auth-channels/${authKey}/url`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * @description channel 목록
   *
   * @tags AdminService
   * @name GetAllChannels
   * @summary channel 목록
   * @request GET:/v2/admin/channels
   * @secure
   * @response `200` `GetAllChannelsData` OK
   */
  getAllChannels = (
    query?: {
      /** 검색어 (post 제목) */
      searchText?: string;
      /** 비즈채널 */
      isBiz?: boolean;
      /** 삭제채널 */
      isDelete?: boolean;
      /** 활성여부 */
      isActive?: boolean;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetAllChannelsData, any>({
      path: `/v2/admin/channels`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description 채널 정보
   *
   * @tags AdminService
   * @name GetChannel2
   * @summary 채널 정보
   * @request GET:/v2/admin/channels/{channelId}
   * @secure
   * @response `200` `GetChannel2Data` OK
   */
  getChannel2 = (channelId: number, params: RequestParams = {}) =>
    this.http.request<GetChannel2Data, any>({
      path: `/v2/admin/channels/${channelId}`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * @description 코인 사용 내역
   *
   * @tags AdminService
   * @name GetCoinHistory
   * @summary 코인 사용 내역
   * @request GET:/v2/admin/channels/{channelId}/coins
   * @secure
   * @response `200` `GetCoinHistoryData` OK
   */
  getCoinHistory = (
    channelId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["transactionDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetCoinHistoryData, any>({
      path: `/v2/admin/channels/${channelId}/coins`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description 전체 광고 목록
   *
   * @tags AdminService
   * @name GetAds
   * @summary 전체 광고 목록
   * @request GET:/v2/admin/ads
   * @secure
   * @response `200` `GetAdsData` OK
   */
  getAds = (
    query?: {
      /** 카테고리 ('의', '식', '주') */
      category?: string;
      /**
       * 'yyyy-MM-dd' (시작 날짜)
       * @format date
       */
      startDate?: string;
      /**
       * 'yyyy-MM-dd' (마지막 날짜)
       * @format date
       */
      endDate?: string;
      /** 광고 진행 상태 */
      status?: 'WAITING' | 'EXPIRED' | 'ONGOING' | 'BLOCKED' | 'CANCELED';
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetAdsData, any>({
      path: `/v2/admin/ads`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description 신고된 광고 목록
   *
   * @tags AdminService
   * @name GetReportedAds
   * @summary 신고된 광고 목록
   * @request GET:/v2/admin/ads/reported
   * @secure
   * @response `200` `GetReportedAdsData` OK
   */
  getReportedAds = (
    query?: {
      /** 카테고리 ('의', '식', '주') */
      category?: string;
      /**
       * 'yyyy-MM-dd' (시작 날짜)
       * @format date
       */
      startDate?: string;
      /**
       * 'yyyy-MM-dd' (마지막 날짜)
       * @format date
       */
      endDate?: string;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetReportedAdsData, any>({
      path: `/v2/admin/ads/reported`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
}
