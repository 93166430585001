import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  DialogTitle,
  Checkbox,
  Select,
  MenuItem,
  IconButton,
  TextField as MuiTextField, // Import TextField as MuiTextField to avoid conflicts
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  useMagazineQuery,
  useCreateMagazineMutation,
  useUpdateMagazineMutation,
  useDeleteMagazineMutation,
} from 'queries/useMagazine';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { CustomFile } from 'types/CommonTypes';
import moment from 'moment';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import styled from 'styled-components';
import FileStore from 'stores/FileStore';
import ImageUploader from 'components/imageUploader';
import { useNavigate, useParams } from 'react-router-dom';
import useSetState from 'hooks/useSetState';
import { MagazineDto, MagazineSocialLinkDto } from 'services/data-contracts';
import { SocialServiceList } from './socialServiceList';

interface MagazineCreatePopupProps {}

const MagazineCreatePopup = ({}: MagazineCreatePopupProps) => {
  const { magazineid } = useParams();
  const popupTitle = magazineid ? '매거진 편집' : '매거진 작성';
  const isNewMagazine = !magazineid;

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const editorRef = useRef<any>(null);
  const fileStore = new FileStore();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [topic, setTopic] = useState('');
  const [author, setAuthor] = useState('');
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
    moment(),
  );
  const [isBanner, setIsBanner] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [thumbnail, setThumbnail] = useState<CustomFile>({});
  const [socialLinks, setSocialLinks] = useState<MagazineSocialLinkDto[]>([
    {
      socialType: 'Instagram',
      linkUrl: 'https://www.instagram.com/ohmoss_official/',
    },
  ]);

  const handleAddSocialLink = () => {
    setSocialLinks([...socialLinks, { socialType: '', linkUrl: '' }]);
  };

  const handleRemoveSocialLink = (index: number) => {
    const updatedLinks = [...socialLinks];
    updatedLinks.splice(index, 1);
    setSocialLinks(updatedLinks);
  };

  const handleSocialLinkChange = (index: number, key: string, value: any) => {
    const updatedLinks = [...socialLinks];
    updatedLinks[index] = {
      ...updatedLinks[index],
      [key]: value,
    };
    setSocialLinks(updatedLinks);
  };

  const uploadImageHandler = (loader: any) => {
    return {
      upload() {
        return new Promise((resolve, reject) => {
          loader.file.then(async (file: any) => {
            const data: any = await fileStore.uploadImage(file);
            resolve({ default: data.publicUrl });
          });
        });
      },
    };
  };

  const editorConfiguration = {
    mediaEmbed: {
      previewsInData: true,
    },
    extraPlugins: [
      function (editor: any) {
        editor.plugins.get('FileRepository').createUploadAdapter = (
          loader: any,
        ) => {
          return uploadImageHandler(loader);
        };
      },
    ],
  };

  const { mutate: createMutate } = useCreateMagazineMutation();
  const { data } = useMagazineQuery(Number(magazineid));
  const { mutate: updateMutate } = useUpdateMagazineMutation();
  const { mutate: deleteMutate } = useDeleteMagazineMutation();

  const getData = () => {
    if (editorRef.current.editor) return editorRef.current.editor.getData();
  };

  const handleClickDelete = async () => {
    const confirm = window.confirm('삭제하시겠습니까?');
    if (confirm) {
      const result = await deleteMutate(Number(magazineid));
      toast('삭제 되었습니다.');
      setTimeout(() => {
        navigate('/magazines');
      }, 400);
    }
  };

  const isValidUrl = (url: string): boolean => {
    const pattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/i;
    return pattern.test(url);
  };

  const handleSaveClick = async () => {
    const isValid = socialLinks.every(
      (link) => isValidUrl(link.linkUrl || '') && link.socialType.trim() !== '',
    );

    if (!isValid) {
      toast.error(
        '유효하지 않은 URL이 포함되어 있거나 소셜서비스가 선택되지 않았습니다.',
      );
      return;
    }

    const newModel: MagazineDto = {
      title,
      topic,
      author,
      publishDate: selectedDate?.toISOString() || '',
      socialLinks,
      content, //: editorRef.current?.editor.getData() || '',
      thumbnail: thumbnail?.blob
        ? { id: (await fileStore.uploadImage(thumbnail.blob)).id }
        : data?.thumbnail
        ? { id: (data?.thumbnail as CustomFile).id }
        : undefined,
      isBanner,
      isActive,
    };

    if ((data?.thumbnail as CustomFile)?.blob) {
      const file = await fileStore.uploadImage(
        (data?.thumbnail as CustomFile)?.blob,
      );
      newModel.thumbnail = { id: file.id };
    }
    if (data?.id && magazineid && newModel) {
      await updateMutate(
        {
          magazineId: Number(magazineid),
          data: newModel,
        },
        {
          onSuccess: async () => {
            toast.success(`매거진을 수정하였습니다.`);
            await queryClient.refetchQueries();
          },
        },
      );
    } else {
      await createMutate(newModel, {
        onSuccess: async () => {
          toast.success(`매거진을 생성하였습니다.`);
          await queryClient.refetchQueries();
        },
      });
    }

    setTimeout(() => {
      navigate('/magazines');
    }, 400);
  };

  useEffect(() => {
    if (data) {
      setTitle(data.title || '');
      setTopic(data.topic || '');
      setAuthor(data.author || '');
      setSocialLinks(data.socialLinks || [{ socialType: '', linkUrl: '' }]);
      // Assuming you have a `setContent` function to set the editor content
      setContent(data.content || '');
      setThumbnail(data.thumbnail || {});
      setSelectedDate(moment(data.publishDate));
      setIsBanner(data.isBanner || false);
      setIsActive(data.isActive || false);
    }
  }, [data]);

  return (
    <>
      <Title>{popupTitle}</Title>
      <>
        <div style={{ display: 'flex', padding: '0 20px 20px 20px' }}>
          <div style={{ flex: 4, paddingRight: '20px' }}>
            <div>
              <CKEditor
                ref={editorRef}
                editor={ClassicEditor}
                config={editorConfiguration}
                data={data?.content || ''}
                onReady={(editor: any) => {
                  console.log('Editor is ready to use!', editor);
                }}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData();
                  // Assuming you have a `setContent` function to set the editor content
                  setContent(data);
                }}
                onBlur={(event: any, editor: any) => {
                  console.log('Blur.', editor);
                }}
                onFocus={(event: any, editor: any) => {
                  console.log('Focus.', editor);
                }}
              />
            </div>
          </div>
          <div style={{ flex: 3 }}>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1, width: '60px' }}>
                <ImageUploader
                  type="single"
                  useCircularCrop={false}
                  initialValue={data?.thumbnail}
                  attachType="image"
                  onSelected={(image: any) => {
                    setThumbnail(image);
                  }}
                />
              </div>
              <div
                style={{ flex: 10, paddingBottom: '10px', paddingLeft: '5px' }}
              >
                <MuiTextField
                  label="제목"
                  fullWidth
                  value={title}
                  // inputRef={textFileldRef}
                  placeholder="제목을 입력해주세요."
                  onChange={(e: any) => {
                    setTitle(e.target.value);
                    // title.current = e.target.value;
                  }}
                />
              </div>
            </div>

            <div style={{ padding: '10px 0' }}>
              <MuiTextField
                label="토픽"
                fullWidth
                value={topic}
                placeholder="토픽을 입력해주세요."
                onChange={(e: any) => {
                  setTopic(e.target.value);
                }}
              />
            </div>
            <div style={{ padding: '10px 0' }}>
              <MuiTextField
                label="작성자"
                fullWidth
                value={author}
                placeholder="작성자를 입력해주세요."
                onChange={(e: any) => {
                  setAuthor(e.target.value);
                }}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ padding: '10px 0' }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="게시일"
                    value={selectedDate}
                    format="YYYY-MM-DD"
                    onChange={(date) => setSelectedDate(date)}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ padding: '15px 20px' }}>
                <label>배너</label>
                <Checkbox
                  checked={isBanner}
                  onChange={(e) => {
                    setIsBanner(e.target.checked);
                  }}
                />
              </div>
              <div style={{ padding: '15px 0px' }}>
                <label>활성</label>
                <Checkbox
                  checked={isActive}
                  onChange={(e) => {
                    setIsActive(e.target.checked);
                  }}
                />
              </div>
            </div>
            <div
              style={{
                paddingTop: '15px',
                borderTop: '1px solid #ccc',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  onClick={handleAddSocialLink}
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                >
                  Link 추가
                </Button>
              </div>
              {socialLinks?.map((link, index) => (
                <div key={index} style={{ paddingTop: '5px' }}>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <Select
                        value={link.socialType || ''}
                        onChange={(e) =>
                          handleSocialLinkChange(
                            index,
                            'socialType',
                            e.target.value,
                          )
                        }
                        displayEmpty
                        style={{ width: '150px' }}
                      >
                        <MenuItem value="" disabled>
                          Select Social Service
                        </MenuItem>
                        {SocialServiceList.map((service, serviceIndex) => (
                          <MenuItem key={serviceIndex} value={service}>
                            {service}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div style={{ paddingLeft: '5px', width: '100%' }}>
                      <MuiTextField
                        fullWidth
                        placeholder="Link URL"
                        value={link.linkUrl || ''}
                        onChange={(e) =>
                          handleSocialLinkChange(
                            index,
                            'linkUrl',
                            e.target.value,
                          )
                        }
                        error={!isValidUrl(link.linkUrl || '')}
                        helperText={
                          !isValidUrl(link.linkUrl || '')
                            ? '유효하지 않은 URL입니다.'
                            : ''
                        }
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: '10px',
                        alignSelf: 'flex-start',
                        paddingTop: '10px',
                      }}
                    >
                      <IconButton
                        onClick={() => handleRemoveSocialLink(index)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
                paddingTop: '15px',
                borderTop: '1px solid #ccc',
                marginTop: '15px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '15px',
                }}
              >
                <div style={{ marginRight: '20px' }}>
                  <Button
                    onClick={handleClickDelete}
                    color="error"
                    variant="contained"
                    disabled={isNewMagazine}
                  >
                    삭제
                  </Button>
                </div>
                <div style={{ display: 'flex' }}>
                  <Button
                    onClick={() => {
                      window.history.back();
                    }}
                    color="info"
                    variant="contained"
                  >
                    목록으로
                  </Button>
                  <Button
                    style={{ marginLeft: '10px' }}
                    onClick={handleSaveClick}
                    color="success"
                    variant="contained"
                  >
                    저장하기
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default MagazineCreatePopup;

const Title = styled(DialogTitle)`
  font-family: inherit;
`;
