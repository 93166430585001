import { AxiosError } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { HttpClient } from 'services/http-client';
import { queryKeys } from './queryKey';
import {
  MagazineDto,
  CreateMagazineData,
  UpdateMagazineData,
  DeleteMagazineData,
} from 'services/data-contracts';
import parsePageInfoFromHeaders from '../utils/parsePageInfoFromHeaders';
import PageInfo from '../types/PageInfo';
import { AdminMagazineService } from 'services/AdminMagazineService';

const magazineService = new AdminMagazineService(new HttpClient());

// Define a new type for the custom query result to include the UseQueryResult for MagazineDto[].
type CustomQueryResult<T> = UseQueryResult<T, AxiosError>;

interface MagazinesResponse {
  magazines: MagazineDto[];
  pageInfo: PageInfo;
}

/**
 * 매거진 목록을 가져오는 커스텀 훅
 *
 * @param options UseQueryOptions<MagazineDto[], AxiosError> 타입의 옵션 객체 (선택 사항)
 * @returns CustomQueryResult<MagazineDto[]> 타입의 쿼리 결과 객체
 */

export function useMagazinesQuery(
  filterOptions: {
    searchText?: string;
    isBanner?: boolean;
    isActive?: boolean;
    page?: number;
    size?: number;
    sort?: string[];
  } = {},

  options?: UseQueryOptions<MagazinesResponse, AxiosError>,
): CustomQueryResult<MagazinesResponse> {
  const defaultOptions: UseQueryOptions<MagazinesResponse, AxiosError> = {
    staleTime: 0,
    cacheTime: 0,
    ...options, // 외부에서 전달된 옵션을 여기에 병합합니다.
  };
  const fetchMagazines = async () => {
    const query = { ...filterOptions };

    const response = await magazineService.getMagazines1(query);
    const pageInfo = parsePageInfoFromHeaders(response.headers);

    return { magazines: response.data, pageInfo };
  };

  return useQuery<MagazinesResponse, AxiosError>(
    [queryKeys.magazines, filterOptions],
    fetchMagazines,
    defaultOptions,
  );
}

/**
 * 특정 매거진 정보를 가져오는 커스텀 훅
 *
 * @param magazineId 가져올 매거진의 ID
 * @param options UseQueryOptions<MagazineDto, AxiosError> 타입의 옵션 객체 (선택 사항)
 * @returns CustomQueryResult<MagazineDto> 타입의 쿼리 결과 객체
 */
export function useMagazineQuery(
  magazineId: number,
  options?: UseQueryOptions<MagazineDto, AxiosError>,
): CustomQueryResult<MagazineDto> {
  const fetchMagazine = async () => {
    const response = await magazineService.getMagazine(magazineId);
    return response.data;
  };

  return useQuery<MagazineDto, AxiosError>(
    queryKeys.magazineById(magazineId),
    fetchMagazine,
    options,
  );
}

/**
 * 매거진 생성
 *
 */
export function useCreateMagazineMutation(
  options?: UseMutationOptions<CreateMagazineData, Error, MagazineDto>,
): UseMutationResult<CreateMagazineData, Error, MagazineDto> {
  const createMagazineMutation = useMutation(
    async (data: MagazineDto): Promise<CreateMagazineData> => {
      const response = await magazineService.createMagazine(data);
      return response.data;
    },
    options,
  );

  return createMagazineMutation;
}

/**
 * 매거진 수정
 *
 */
export function useUpdateMagazineMutation(
  options?: UseMutationOptions<
    UpdateMagazineData,
    Error,
    { magazineId: number; data: MagazineDto }
  >,
): UseMutationResult<
  UpdateMagazineData,
  Error,
  { magazineId: number; data: MagazineDto }
> {
  const updateMagazineMutation = useMutation(
    async (params: {
      magazineId: number;
      data: MagazineDto;
    }): Promise<UpdateMagazineData> => {
      const { magazineId, data } = params;
      const response = await magazineService.updateMagazine(magazineId, data);
      return response.data;
    },
    options,
  );

  return updateMagazineMutation;
}

export function useDeleteMagazineMutation(
  options?: UseMutationOptions<DeleteMagazineData, Error, number>,
): UseMutationResult<DeleteMagazineData, Error, number> {
  const deleteMagazineMutation = useMutation(
    async (magazineId: number): Promise<DeleteMagazineData> => {
      const response = await magazineService.deleteMagazine(magazineId);
      return response.data; // 응답 데이터를 반환합니다. 응답 객체에서 data 속성을 추출하여 반환합니다.
    },
    options,
  );

  return deleteMagazineMutation;
}
