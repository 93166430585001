import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from 'pages/LoginPage';
import Layout from 'components/layout/Layout';
import WithAuthentication from 'hoc/Auth';
import MenuItems from './MenuItems';
// import { ErrorBoundary } from 'react-error-boundary';
// import ErrorFallback from 'pages/ErrorFallback';

const AppRouter = () => {
  const AuthLayout = WithAuthentication(Layout, true);
  const AuthLoginPage = WithAuthentication(LoginPage, false);

  return (
    <>
      <BrowserRouter>
        {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
        <Routes>
          <Route path="/login" element={<AuthLoginPage />} />
          <Route path="/" element={<Navigate replace to="/dashboard" />} />

          <Route element={<AuthLayout />}>
            {MenuItems.map((menuItem) => (
              <Route
                key={menuItem.path}
                path={menuItem.path}
                element={menuItem.component}
              />
            ))}
          </Route>
        </Routes>
        {/* </ErrorBoundary> */}
      </BrowserRouter>
    </>
  );
};
export default AppRouter;
