import { useNavigate, Link } from 'react-router-dom';
import { useAuthStore } from 'stores/AuthStore';
import styled from 'styled-components';
import MenuItems, { MenuItem } from '../../MenuItems';

const HeaderWrapper = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: #00632b;
  color: #fff;
`;

const HeaderContent = styled.div`
  display: flex;
  width: 96%;
  height: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between; /* 변경된 부분: 좌측 정렬 */
`;

const Logo = styled.div`
  color: #ffffff;
  font-size: 18px;
`;

const Navigation = styled.nav`
  ul {
    display: flex;
    list-style: none;

    li + li {
      margin-left: 30px;
    }
  }
`;

// const MenuItem = styled.li`
//   /* Add any styles specific to menu items here */
// `;

const MenuItemLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;

  &:hover {
    /* hover 스타일 지정 */
  }
`;

const LogoutButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #ffffff;
`;

const Header = () => {
  const authStore = useAuthStore();
  const navigate = useNavigate();

  const handleLogout = () => {
    authStore.logout();
    navigate('/login');
  };

  return (
    <HeaderWrapper>
      <HeaderContent>
        <Logo>oh!moss admin</Logo>
        <Navigation>
          <ul>
            {MenuItems.filter((item) => item.isMenu).map(
              (menuItem: MenuItem) => (
                <li key={menuItem.path}>
                  <MenuItemLink to={menuItem.path}>
                    {menuItem.label}
                  </MenuItemLink>
                </li>
              ),
            )}
          </ul>
        </Navigation>
        <LogoutButton onClick={handleLogout}>로그아웃</LogoutButton>
      </HeaderContent>
    </HeaderWrapper>
  );
};

export default Header;
